<template>
	<div>
		<v-tabs
			v-model="current_tab"
			background-color="transparent"
			active-class="black--text font-weight-bold"
			centered
			slider-color="error"
			fixed-tabs
		>
			<v-tab
				v-for="(tab, index) in tabs"
				:key="index"
				:href="`#${tab.value}`"
				class="title"
			>
				<v-icon
					:left="!$vuetify.rtl"
					:right="$vuetify.rtl"
					:color="current_tab === tab.value ? 'error' : null"
					>{{ $vuetify.icons.values[tab.icon] }}</v-icon
				>
				{{ $t(tab.name) }}
			</v-tab>
		</v-tabs>

		<v-tabs-items v-model="current_tab" class="wr_grey_1">
			<v-tab-item
				v-for="(tab, index) in tabs"
				:key="'c' + index"
				:value="tab.value"
			>
				<Interview :interview_id="interview_id"></Interview>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import Interview from "@/components/shared/tools/interview/Index.vue";

export default {
	name: "InterviewArrangements",

	props: {
		interview_id: {
			required: false, // Interactions and networking stage will send the interview_id.
		},
	},

	components: {
		Interview,
	},

	computed: {
		...mapFields("interview", {
			current_tab: "current_tab",
		}),

		tabs() {
			return [
				{
					name: "app.interviews.plan_interview.title",
					value: "plan-interview",
					icon: "clipboard_text",
				},
				{
					name: "app.interviews.document_interview.title",
					value: "document-interview",
					icon: "document",
				},
			];
		},
	},
};
</script>
